import React from 'react'
import { entries } from 'lodash'

import Map from '../Map'
import ProductButtons from './ProductButtons'
import ProductGallery from './ProductGallery'

import Stars from './RatingStars'
import ReadMoreText from '../ReadMoreText'

const ProductDetailHotel = props => {
  const { hotel, forms, destinationLabel, pageContext } = props
  const { images, stars } = hotel
  const mainText = hotel.description?.freetext_1?.description

  // console.log('ProductDetailHotel', hotel)

  return (
    <>
      <div className='content-element story-group page-intro'>
        <h1>{hotel?.title}</h1>
        {(stars || stars !== 0) && (
          <div className='rating'>
            <Stars num={stars} />
          </div>
        )}
        <h2>{hotel?.location}</h2>

        {/* <div>{mainText}</div> */}
        {mainText && <ReadMoreText>{mainText}</ReadMoreText>}
      </div>

      <div className='content-element bg-white'>
        <div className='product-detail'>
          <div className='row'>
            <div className='col-sm-4'>
              <div className='product-info'>
                <div className='header'>
                  <p>
                    <strong>Beschreibung</strong>
                  </p>
                  <div className='separator'></div>

                  <div className='mt-3'>
                    {entries(hotel.description).map((section, index) => {
                      const sectionKey = section[0]
                      const sectionData = section[1]
                      const { title, description } = sectionData

                      // ignore 'freetext_1' (Unsere Meinung) here, as we already used it as 'mainText' on top
                      if (sectionKey !== 'freetext_1') {
                        return (
                          <div key={index} className='mt-3'>
                            <strong>{title}</strong>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: description
                              }}
                            />
                          </div>
                        )
                      }
                      return ''
                    })}
                  </div>

                  <div className='mt-3'>
                    <strong>
                      {hotel.price ? (
                        <>
                          ab CHF {hotel.price}.– /{' '}
                          <span className='price-period'>pro Person</span>
                        </>
                      ) : (
                        'Preis auf Anfrage'
                      )}
                    </strong>
                  </div>

                  <ProductButtons
                    forms={forms}
                    productName={hotel.title}
                    pageContext={pageContext}
                    productType='hotel'
                    destinationLabel={destinationLabel}
                  />
                </div>
              </div>
            </div>

            <div className='col-sm-8'>
              <div className='map'>
                {hotel.geo && (
                  <Map
                    center={[hotel.geo.lat, hotel.geo.long]}
                    zoom={13}
                    height={500}
                  />
                )}
              </div>
              <div className='row gallery-hotel'>
                <ProductGallery images={images} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductDetailHotel
