import React, { useState } from 'react'
import Img from 'gatsby-image'
import Lightbox from 'react-image-lightbox'

import { getFluidImage } from '../../utils/images'

const ProductGallery = ({ images }) => {
  const fluidImages = images.map(i => getFluidImage({ ...i, ar: 1.7 }))
  const imageSources = fluidImages.map(i => i.src)

  // lightbox props
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const prevIndex = (photoIndex + imageSources.length - 1) % imageSources.length
  const nextIndex = (photoIndex + 1) % imageSources.length

  return (
    <>
      <div className='product-gallery'>
        {images.map((image, index) => (
          <div
            className='product-gallery-item col-xs-6 hover-zoom'
            key={index}
            role='button'
            tabIndex={index}
            onKeyDown={() => {}}
            onClick={() => {
              setIsOpen(true)
              setPhotoIndex(index)
            }}
          >
            <Img
              fluid={fluidImages[index]}
              alt={image.alt || 'product gallery item'}
              title={image.alt}
              loading='lazy'
              placeholderStyle={{ backgroundColor: 'lightgray' }}
            />
          </div>
        ))}
      </div>

      {isOpen && (
        <Lightbox
          onCloseRequest={() => setIsOpen(false)}
          mainSrc={imageSources[photoIndex]}
          nextSrc={imageSources[nextIndex]}
          prevSrc={imageSources[prevIndex]}
          onMovePrevRequest={() => setPhotoIndex(prevIndex)}
          onMoveNextRequest={() => setPhotoIndex(nextIndex)}
          enableZoom={false}
        />
      )}
    </>
  )
}

export default ProductGallery
