import React, { useState } from 'react'

import { isDomAvailable } from '../../utils/helpers'
import Modal from '../Modal'
import HubSpotForm from '../HubSpotForm'
import Form from '../Form' // fallback
import ThemeContext from '../../context/ThemeContext'

const ProductButtons = props => {
  const {
    forms,
    productName,
    showOnlyFirstButton,
    productType,
    destinationLabel,
    pageContext
  } = props

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [currentForm, setCurrentForm] = useState(null)
  const noDescFormDestinations = [
    'sport',
    'trainingslager',
    'golf',
    'sportlive',
    'strand'
  ]
  const currentDestinationName = pageContext?.destination?.name?.value || ''
  const toggleModal = (type, formName) => {
    setModalIsOpen(!modalIsOpen)

    if (type === 'form') {
      if (forms[formName]) {
        if (
          noDescFormDestinations.includes(currentDestinationName) &&
          forms[formName]?.settings?.formData?.desc
        ) {
          // do not display form description on the Sportreisen Specialists form
          forms[formName].settings.formData.desc = ''
        }
      }
      setCurrentForm(formName || null)
    } else {
      setCurrentForm(null)
    }
  }

  const printPage = () => {
    isDomAvailable() && window.print()
  }

  const email = {
    subject: encodeURIComponent(productName),
    body: encodeURIComponent(
      `Ich habe beim Spezialisten Knecht Reisen meine Traumreise entdeckt. Schau mal rein: ${
        isDomAvailable() && window.location.href
      }`
    )
  }

  // fallback
  const renderFormComponent = form => {
    const tmpForm = form.settings.formData
    const isHubSpotForm = tmpForm?.snippet && tmpForm.snippet ? true : false

    if (isHubSpotForm) {
      return (
        <HubSpotForm
          settings={tmpForm}
          additionalSettings={{ destination: pageContext?.destination }}
          stringVars={{ subHeader: productName }}
          defaultValues={{
            productname: productName,
            destinationlabel: destinationLabel
          }}
          allHsForms={pageContext?.hsForms}
        />
      )
    } else {
      return (
        <Form
          settings={form.settings}
          stringVars={{ subHeader: productName }}
          defaultValues={{ productName, destinationLabel }}
        />
      )
    }
  }

  return (
    <ThemeContext.Consumer>
      {color => (
        <>
          <Modal modalIsOpen={modalIsOpen} toggleModal={toggleModal}>
            {forms[currentForm] ? (
              renderFormComponent(forms[currentForm])
            ) : (
              <div className='product-modal-share'>
                <a href={`mailto:?subject=${email.subject}&body=${email.body}`}>
                  <button
                    className='btn-outline'
                    style={{ color, borderColor: color }}
                  >
                    Email
                  </button>
                </a>
                <a
                  href={`https://wa.me/?text=${email.body}`}
                  data-action='share/whatsapp/share'
                >
                  <button
                    className='btn-outline'
                    style={{ color, borderColor: color }}
                  >
                    WhatsApp
                  </button>
                </a>
              </div>
            )}
          </Modal>

          <div className='product-buttons button mt-3'>
            <button
              className='btn-fill btn-100 mt-2'
              onClick={() =>
                toggleModal(
                  'form',
                  productType === 'hotel' ? 'Kontakt' : 'GratisOfferte'
                )
              }
              style={{ background: color }}
            >
              Offertenanfrage
            </button>
            {!showOnlyFirstButton && (
              <>
                <button
                  className='btn-outline btn-100 mt-2'
                  style={{ color, borderColor: color }}
                  onClick={() => toggleModal('form', 'Termin')}
                >
                  Persönliche Beratung
                </button>
                <button
                  className='btn-outline btn-100 mt-2'
                  style={{ color, borderColor: color }}
                  onClick={printPage}
                >
                  PDF
                </button>

                <button
                  className='btn-outline btn-100 mt-2'
                  style={{ color, borderColor: color }}
                  onClick={() => toggleModal('share')}
                >
                  Einem Freund senden
                </button>
              </>
            )}
          </div>
        </>
      )}
    </ThemeContext.Consumer>
  )
}

export default ProductButtons
