import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../layouts/MainLayout'
import ProductDetailRoundtrip from '../components/Product/ProductDetailRoundtrip'
import ProductDetailHotel from '../components/Product/ProductDetailHotel'
import { getFluidImage } from '../utils/images'
import { getDestinationLabel } from '../utils/helpers'

// ENV variables
const envFile = require('../../env')
const env = envFile.env[process.env.BENV || 'prod']

const ProductTemplate = props => {
  const {
    pageContext: {
      page,
      knechtDestination,
      mainDestination,
      destination,
      product,
      productType,
      url,
      absoluteUrl,
      langSlug,
      lang,
      forms,
      hsForms,
      destinations,
      newsletterForm,
      isRootPage
    }
  } = props

  const currentPageUrl = url || ''
  const mainDestinationMenuItems = knechtDestination?.menuItems?.value || []
  const destinationMenuItems = destination?.menuItems?.value || []

  const getDestinationName = () => {
    return getDestinationLabel(
      currentPageUrl,
      isRootPage,
      mainDestinationMenuItems,
      destinationMenuItems
    )
  }

  // single color for all pages
  if (env.single_color) {
    switch (typeof env.single_color) {
      case 'boolean':
        destination.color.value = knechtDestination.color.value
        break
      case 'string':
        destination.color.value = env.single_color
        break
      default:
    }
  }

  let itinerarySchema = []
  // Create array of QA for Schema.org
  // Strip HTML from answers
  product.program &&
    product.program.forEach(program => {
      const title = program.title
      const text = program.text || ''
      if (title) {
        itinerarySchema.push({
          '@type': 'City',
          name: title,
          description: text
        })
      }
    })

  const detailComponents = {
    roundtrips: (
      <>
        <ProductDetailRoundtrip
          pageContext={{ page, destination, hsForms }}
          roundtrip={product}
          forms={forms}
          destinationLabel={getDestinationName()}
        />
        {/* Schema.org tags */}
        <Helmet>
          {product && (
            <script type='application/ld+json'>
              {`{
                "@context": "https://schema.org",
                "@type": "Trip",
                "name": "${product.title || ''}",
                "description": "${product.intro || ''}",
                "itinerary": ${JSON.stringify(itinerarySchema)}
            }`}
            </script>
          )}
        </Helmet>
      </>
    ),
    hotels: (
      <>
        <ProductDetailHotel
          pageContext={{ page, destination, hsForms }}
          hotel={product}
          forms={forms}
          destinationlabel={getDestinationName()}
        />
        {/* Schema.org tags */}
        <Helmet>
          {product && (
            <script type='application/ld+json'>
              {`{
            "@type" : "Hotel",
            "name" : "${product?.seo?.h1 || ''}",
            "description" : "${product?.seo?.description || ''}",
            "image" : "${
              product?.images[0]?.cloudinary &&
              getFluidImage({
                cloudinary: product?.images[0]?.cloudinary,
                ar: 1.7,
                width: '20px',
                maxWidth: 363,
                widths: [320, 363]
              })?.src
            }",
            "@context" : "http://schema.org",
            "address" : {
                "@type" : "PostalAddress",
                "addressRegion" : "${product?.region?.name || ''}",
                "addressLocality" : "",
                "postalCode" : "",
                "streetAddress" : "",
                "addressCountry" : "${product?.country?.name || ''}"
            },
            "priceRange" : "",
            "hasMap" : "",
            "url" : "${absoluteUrl}"}`}
            </script>
          )}
        </Helmet>
      </>
    )
  }

  let meta = []
  if (product.noindex) {
    meta.push({ name: 'robots', content: 'noindex' })
  }

  const getProductMetaTitle = () => {
    let title = product.seo?.htmlTitle || ''
    if (product?.from) {
      title = `${product.title} ab ${product.from}`
    } else if (product?.location) {
      title = `${product.title} ab ${product.location}`
    }
    return title
  }

  return (
    <Layout
      pageContext={{
        page: { ...page, headerImage: null },
        knechtDestination,
        mainDestination,
        destination,
        destinations,
        url,
        lang,
        langSlug,
        absoluteUrl,
        hsForms,
        newsletterForm
      }}
    >
      <Helmet
        title={getProductMetaTitle()}
        description={product.seo?.htmlDescription}
        meta={meta}
      />

      {detailComponents[productType]}
    </Layout>
  )
}

export default ProductTemplate
